import { createSlice } from '@reduxjs/toolkit';
import { window } from '../../global';

const defaultEntitiesState = typeof window.initialState !== "undefined" && window.initialState.entities ? window.initialState.entities : {};
const defaultDisplayState = typeof window.initialState !== "undefined" && window.initialState.display ? window.initialState.display : {};
const defaultDropdownState = typeof window.initialState !== "undefined" && window.initialState.dropdowns ? window.initialState.dropdowns : {};

const slice = createSlice({
    name: "analytics",
    initialState: {
        entities: defaultEntitiesState,
        display: defaultDisplayState,
        dropdowns: defaultDropdownState,
    },
    reducers: {
    },
});

export default slice;
