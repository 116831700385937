import React from 'react';
import { Col, H3, Row, Box } from '@commonsku/styles';
import { useIdentity } from '../../hooks';
import { LabeledSelect } from '../helpers';

function DefaultAnalytics(props) {
  const getPageTitle = () => {
    let pageTitle = 'Analytics';
    switch (props.analytics_type) {
      case 'MANAGEMENT_ANALYTICS':
        pageTitle = 'Management Analytics';
        break;
      case 'SALES_ANALYTICS':
        pageTitle = 'Sales Analytics';
        break;
      case 'FINANCE_ANALYTICS':
        pageTitle = 'Finance Analytics';
        break;
      default:
        break;
    }

    return pageTitle;
  };

  function renderFilters() {
    return (
      <Row style={{marginTop: 0, marginBottom: 20, }}>
        <Col xs md={4} padded>
          <LabeledSelect options={[{label: 'All Rep Types', value: ''}]} value="" label="Rep Type" className="csku-select-v3" />
        </Col>
        <Col xs md={4} padded>
          <LabeledSelect options={[{label: 'All Reps', value: ''}]} value="" label="Rep" className="csku-select-v3" />
        </Col>
        <Col xs md={4} padded>
          <LabeledSelect options={[{label: 'All Clients', value: ''}]} value="" label="Client" className="csku-select-v3" />
        </Col>
      </Row>
    );
  }

  function renderContent() {
    switch (props.analytics_type) {
      case 'MANAGEMENT_ANALYTICS':
        return renderManagementAnalytics();
      case 'SALES_ANALYTICS':
        return renderSalesAnalytics();
      case 'FINANCE_ANALYTICS':
        return renderFinanceAnalytics();
    }

    return (<div></div>);
  }

  function renderSalesAnalytics() {
    return (
      <Row>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/sales_a_1.1.png" />
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/sales_a_1.2.png" />
        </Col>
        <Col xs>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/sales_a_2.png" />
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/sales_a_3.1.png" />
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/sales_a_3.2.png" />
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/sales_a_4.1.png" />
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/sales_a_4.2.png" />
        </Col>
      </Row>
    );
  }

  function renderFinanceAnalytics() {
    return (
      <Row>
        <Col xs>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/finance_a_1.png" />
        </Col>
        <Col xs md={6}>
          <Row>
            <Col xs>
              <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/finance_a_2.1.png" />
            </Col>
            <Col xs>
              <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/finance_a_2.3.png" />
            </Col>
          </Row>
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/finance_a_2.2.png" />
        </Col>
      </Row>
    );
  }

  function renderManagementAnalytics() {
    return (
      <Row>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/management_a_1.1.png" />
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/management_a_1.2.png" />
        </Col>
        <Col xs>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/management_a_2.png" />
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/management_a_3.1.png" />
        </Col>
        <Col xs md={6}>
          <img style={{ maxWidth: '100%', }} src="/images/analytics_v2/management_a_3.2.png" />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col xs padded>
        {renderFilters()}
        {renderContent()}
      </Col>
    </Row>
  );
}

function AnalyticsContainer(props) {
  const identity = useIdentity();

  return (
    <DefaultAnalytics analytics_type={props.analytics_type} />
  );
}

export default AnalyticsContainer;
