import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ErrorBoundary, Theme, SizerWrapper, themeOptions, TabBar, Tab, } from '@commonsku/styles';
import { document, window } from '../global';
import { oauth } from '../utils';
import withReducers from '../store/withReducers';
import slice from '../components/analytics/analyticsSlice';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import createPopup from '../popup-factory';
import { getPopups } from '../selectors';
import { createNewFeaturePopup } from '../actions/popup';
import AnalyticsContainer from '../components/analytics/AnalyticsContainer';
import { createGlobalStyle } from '../components/helpers';

/**
 * On component did mount
 * @param {Object} data
 */
function onInit({user_id, onCreateNewFeaturePopup}) {
  // remove resku from body and html tags
  document.getElementsByTagName('body')[0].className = '';
  document.getElementsByTagName('html')[0].className = '';
  document.body.style = "background: #EDF4F7;";
  oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
    if (json.show_popup == 1) {
      onCreateNewFeaturePopup && onCreateNewFeaturePopup();
    }
  });
}

export const GlobalStyle = createGlobalStyle(p => ``);

function AnalyticsApp({
  identity,

  onCreateNewFeaturePopup,
  popups,
  ...props
}) {
  useEffect(function () {
    onInit({user_id: identity.user_id, onCreateNewFeaturePopup});
  }, []);

  const tabs = [
    {label: "Sales Analytics", content: "", link: 'sales_analytics.php', key: 'sales_analytics',
      show: window.location.pathname.indexOf('sales_analytics') !== -1},
    {label: "Finance Analytics", content: "", link: 'finance_analytics.php', key: 'finance_analytics',
      show: window.location.pathname.indexOf('finance_analytics') !== -1},
    {label: "Management Analytics", content: "", link: 'management_analytics.php', key: 'management_analytics',
      show: window.location.pathname.indexOf('management_analytics') !== -1},

    {label: "Sales Rep Report", content: "", link: 'report_sales_rep.php', key: 'report_sales_rep'},
    {label: "Sales Dashboard", content: "", link: 'report_sales_dashboard.php', key: 'report_sales_dashboard'},
    {label: "Commission Report", content: "", link: 'report_commission.php', key: 'report_commission'},
    {label: "Sales Target Report", content: "", link: 'report_sales_target.php', key: 'report_sales_target'},
    {label: "Sample Report", content: "", link: 'report_sample.php', key: 'report_sample'},
    {label: "Mailing List Report", content: "", link: 'report_mailing_list.php', key: 'report_mailing_list'},
  ];

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle theme={{ ...themeOptions }} />
      <ErrorBoundary>
        <div className="resku" style={{background: '#EDF4F7'}}>
          <Header>
            <div className="columns"></div>
          </Header>
        </div>
        <div>
          <div className="resku"><MainSection /></div>

          <div className="main-content csku-styles" style={{ marginTop: '0rem', background: '#EDF4F7', }}>
            <SizerWrapper xsStyle="padding-left: 0; margin-left: 0;" smStyle="margin-left: 165px; padding-right: 0 !important; padding-left: 10px !important;" mdStyle="padding-left: 10px;" style={{paddingRight: 0}}>
              <AnalyticsContainer analytics_type={props.analytics_type} />
            </SizerWrapper>
          </div>
          <div className="resku">
            {popups ? popups.map((p, idx) => createPopup(p, idx, props)) : null}
          </div>
        </div>
        <div className="resku">
          <Overlay popups={popups} />
        </div>
      </ErrorBoundary>
    </Theme>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    popups: getPopups(state),
    filterOptions: {
    },
    loading: state.display.loading,
    analytics_type: state.entities.analytics_type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    },
  };
};

export default withReducers(connect(mapStateToProps, mapDispatchToProps)(AnalyticsApp), slice.reducer);
